import React from "react";

import {Button} from "@pg-design/button";
import {BrandWeightIcon, CheckIcon} from "@pg-design/icons-module";
import {Popover} from "@pg-design/notifications";

import {useFavourites} from "../../favourite/hooks/use_favourites";
import {hitGtmAddToCompareClick} from "../../favourite/tracking/favourite_gtm_events";
import {handleFavouritesListCheckHit} from "../../tracking/algolytics_hits/favourites_list_hit";

interface IProps {
    className?: string;
    offerId: number;
    propertyId: number;
    theme: "dark" | "light";
}

export const AddToFavouritesButton = (props: IProps) => {
    const {offerId, propertyId, theme, className} = props;

    const {isPropertyInFavourites, addPropertyToFavourites, removePropertyFromFavourites} = useFavourites();

    const isInFavourites = isPropertyInFavourites(propertyId);

    const onFavouriteToggle = async () => {
        if (isInFavourites) {
            await removePropertyFromFavourites(propertyId);

            hitGtmAddToCompareClick(false);
            handleFavouritesListCheckHit(false, offerId, propertyId);
        } else {
            await addPropertyToFavourites(propertyId, offerId);

            hitGtmAddToCompareClick(true);
            handleFavouritesListCheckHit(true, offerId, propertyId);
        }
    };

    return (
        <Popover isActive body={<div>Dodaj nieruchomość aby skorzystać z porównywarki</div>} popoverPlace="left" toggleHoverHandlers toggleBlurHandler>
            <Button
                variant={isInFavourites ? "filled_primary" : theme === "dark" ? "outlined_secondary_light" : "outlined_secondary"}
                size="small"
                iconLeft={isInFavourites ? CheckIcon : BrandWeightIcon}
                onClick={onFavouriteToggle}
                className={className}
            >
                {isInFavourites ? "Dodano do porównania" : "Dodaj do porównania"}
            </Button>
        </Popover>
    );
};
