import React from "react";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize, mb} from "@pg-design/helpers-css";
import {FieldWrapper} from "@pg-design/inputs";
import {RangeInput} from "@pg-design/inputs";
import {SystemModal} from "@pg-design/modal";
import {CheckboxControlled, ControllerRHF, useFormRHF} from "@pg-design/react-hook-forms-utils";
import {Text} from "@pg-design/text-module";

import {IOfferModalState} from "../../offer/types/OfferModalState";
import {SearchFiltersRooms} from "../../search/components/filters/SearchFiltersRooms";
import {useUserPreferences} from "../../user/hooks/use_user_preferences";

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    handleConfirm: (values: IFiltersFormValues) => void;
}

export interface IFiltersFormValues {
    rooms: IOfferModalState["filters"]["rooms"];
    area: IOfferModalState["filters"]["area"];
    floorGround: boolean;
    floorGroundWithGarden: boolean;
    floorOneToFour: boolean;
    floorFivePlus: boolean;
    floorLast: boolean;
    floorLastWithTerrace: boolean;
}

const castToNumberIfExists = (value: string | undefined) => {
    return value ? Number.parseInt(value) : "";
};

export function PropertyListFiltersModal(props: IProps) {
    const {isOpen, handleClose, handleConfirm} = props;
    const {userPreferences, saveUserPreferences} = useUserPreferences();
    const userPreferencesFilters = userPreferences?.data?.filters || {};

    const theme = useTheme();

    const formHook = useFormRHF<IFiltersFormValues>({
        defaultValues: {
            rooms: {lower: castToNumberIfExists(userPreferencesFilters.rooms_0), upper: castToNumberIfExists(userPreferencesFilters.rooms_1)},
            area: {lower: userPreferencesFilters.area_0 || "", upper: userPreferencesFilters.area_1 || ""},
            floorGround: Boolean(userPreferencesFilters.floor_choices?.includes("1")),
            floorGroundWithGarden: Boolean(userPreferencesFilters.floor_choices?.includes("2")),
            floorOneToFour: Boolean(userPreferencesFilters.floor_choices?.includes("3")),
            floorFivePlus: Boolean(userPreferencesFilters.floor_choices?.includes("4")),
            floorLast: Boolean(userPreferencesFilters.floor_choices?.includes("5")),
            floorLastWithTerrace: Boolean(userPreferencesFilters.floor_choices?.includes("6"))
        }
    });
    const {handleSubmit, control} = formHook;

    const handlers = {
        onSubmit: (data: IFiltersFormValues) => {
            saveUserPreferences({
                filters: {
                    rooms_0: data.rooms.lower?.toString() || undefined,
                    rooms_1: data.rooms.upper?.toString() || undefined,
                    area_0: data.area.lower?.toString() || undefined,
                    area_1: data.area.upper?.toString() || undefined,
                    floor_choices: [
                        data.floorGround ? "1" : "",
                        data.floorGroundWithGarden ? "2" : "",
                        data.floorOneToFour ? "3" : "",
                        data.floorFivePlus ? "4" : "",
                        data.floorLast ? "5" : "",
                        data.floorLastWithTerrace ? "6" : ""
                    ].filter(Boolean)
                }
            });
            handleConfirm(data);
        },
        onCancel: () => {
            handleClose();
        }
    };

    return (
        <SystemModal isOpen={isOpen} variant="fit" headerVariant="gray" onModalClose={handlers.onCancel}>
            <SystemModal.Header>
                <Text variant="body_copy_2">Filtry</Text>
            </SystemModal.Header>
            <SystemModal.Content css={modalContent}>
                <form onSubmit={handleSubmit(handlers.onSubmit)} css={formStyle}>
                    <FieldWrapper
                        labelContent={
                            <Text variant="body_copy_1" color={theme.colors.gray[700]} css={mb(1)}>
                                Pokoje
                            </Text>
                        }
                        css={mb(3)}
                    >
                        <ControllerRHF
                            control={control}
                            name="rooms"
                            render={({field}) => (
                                <SearchFiltersRooms
                                    {...field}
                                    css={mb(5)}
                                    toggleDropdown={() => null}
                                    clearField={() => null}
                                    showFooter={false}
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                    }}
                                    onAfterChange={() => null}
                                />
                            )}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        labelContent={
                            <Text variant="body_copy_1" color={theme.colors.gray[700]} css={mb(1)}>
                                Metraż
                            </Text>
                        }
                        css={mb(3)}
                    >
                        <ControllerRHF
                            control={control}
                            name="area"
                            render={({field}) => (
                                <RangeInput
                                    {...field}
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                    }}
                                />
                            )}
                        />
                    </FieldWrapper>
                    <FieldWrapper
                        labelContent={
                            <Text variant="body_copy_1" color={theme.colors.gray[700]} css={mb(1)}>
                                Piętro
                            </Text>
                        }
                        css={checkboxList}
                    >
                        <CheckboxControlled control={control} name="floorGround" labelContent="Parter" />
                        <CheckboxControlled control={control} name="floorGroundWithGarden" labelContent="Parter z ogródkiem" />
                        <CheckboxControlled control={control} name="floorOneToFour" labelContent="Niskie piętra (1-4)" />
                        <CheckboxControlled control={control} name="floorFivePlus" labelContent="Wysokie piętra (5+)" />
                        <CheckboxControlled control={control} name="floorLast" labelContent="Ostatnie piętro" />
                        <CheckboxControlled control={control} name="floorLastWithTerrace" labelContent="Ostatnie piętro z tarasem" />
                    </FieldWrapper>
                    <div css={modalFooter}>
                        <Button variant="filled_primary" size="small" css={fullWidthButton}>
                            Wybierz
                        </Button>

                        <Button variant="none_secondary" onClick={handlers.onCancel} size="small">
                            Wyczyść
                        </Button>
                    </div>
                </form>
            </SystemModal.Content>
        </SystemModal>
    );
}

const modalContent = css`
    flex: 1;
    display: flex;
    flex-direction: column;

    & > div {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`;

const formStyle = css`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const checkboxList = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
    ${mb(5)};
`;

const modalFooter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const fullWidthButton = css`
    width: 100%;
`;
