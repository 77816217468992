import {css, useTheme} from "@emotion/react";

import {calculateRemSize as crs} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

export function PropertyPlanWallsLegend({className}: {className?: string}) {
    const theme = useTheme();

    return (
        <div css={wallsLegend} className={className}>
            <div css={wallsLegend}>
                <div css={bearingWall} />
                <Text variant="info_txt_2" color={theme.colors.gray[800]}>
                    Ściana nośna
                </Text>
            </div>
            <div css={wallsLegend}>
                <div css={partitionWall} />
                <Text variant="info_txt_2" color={theme.colors.gray[800]}>
                    Ściana działowa
                </Text>
            </div>
        </div>
    );
}

//  Styles
const wallsLegend = css`
    display: flex;
    column-gap: ${crs(0.5)};
`;

const bearingWall = css`
    width: ${crs(5)};
    height: ${crs(1.5)};
    background: #484849;
`;

const partitionWall = css`
    width: ${crs(5)};
    height: ${crs(1.5)};
    background: #888888;
`;
