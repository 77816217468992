import {useEffect, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {propertyPlan2dTracking} from "../../tracking/property_plans2d_tracking";
import {ISimhomeAreaType, ISimhomeRoom} from "../../types/isimhome_property";
import {getRpAreaColor} from "../../utils/get_rp_area_color";

interface IProps {
    propertyRooms: ISimhomeRoom[];
    planSvgUrl: string;
    selectedAreaId: string | null;
    handleSelectArea: (areaId: string | null) => void;
    className?: string;
}

export function PropertyPlanImage(props: IProps) {
    const {propertyRooms, selectedAreaId, handleSelectArea, planSvgUrl, className} = props;
    const [svgXml, setSvgXml] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const theme = useTheme();

    useEffect(() => {
        setIsLoading(true);
        fetch(planSvgUrl)
            .then((res) => res.text())
            .then((svgXml) => {
                setSvgXml(svgXml as string);
                setIsLoading(false);
            });
    }, [planSvgUrl]);

    useEffect(() => {
        const handlePathClick = (event: Event) => {
            const pathElement = event.target as SVGPathElement | null;
            if (pathElement && pathElement.parentElement && pathElement.parentElement.hasAttribute("data-id")) {
                const areaId = pathElement.parentElement.getAttribute("data-id");

                propertyPlan2dTracking.gtm.clickOnPropertyPlanRoom();
                handleSelectArea(areaId);
            }
        };

        if (svgXml) {
            for (const room of propertyRooms) {
                const roomArea = document.querySelector(`[data-id=${CSS.escape(room.planner_id)}]`);

                roomArea?.addEventListener("click", handlePathClick);
            }
        }

        return () => {
            for (const room of propertyRooms) {
                const roomArea = document.querySelector(`[data-id=${CSS.escape(room.planner_id)}]`);

                roomArea?.removeEventListener("click", handlePathClick);
            }
        };
    }, [svgXml]);

    const roomsColor: ISimhomeColorData[] = propertyRooms.map(({area_type, room_type, planner_id}) => ({
        area_type,
        room_type,
        planner_id
    }));

    const areaStyles = getAreaStyles(roomsColor, selectedAreaId, theme);

    return (
        // NOTE: Hide svg instead of unmounting to prevent visual flickering when the next/previous SVG (with different width) is loading
        <div className={className} css={[planImage, areaStyles, isLoading && hidden]}>
            {svgXml && <div css={svgWrap} dangerouslySetInnerHTML={{__html: svgXml}} />}
        </div>
    );
}

//  Styles
const planImage = css`
    & svg {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
`;

const svgWrap = css`
    max-width: 100%;
    max-height: 100%;
`;

const hidden = css`
    visibility: hidden;
`;

type ISimhomeColorData = Pick<ISimhomeRoom, "area_type" | "room_type" | "planner_id">;

function getAreaStyles(roomColorData: ISimhomeColorData[], selectedAreaId: string | null, theme: Theme) {
    return roomColorData.map((colorData) => {
        const isSelected = colorData.planner_id === selectedAreaId;
        const areaColor = getRpAreaColor(colorData.area_type, colorData.room_type, isSelected, theme);

        if (colorData.room_type === null && colorData.area_type === ISimhomeAreaType.EXCLUDED_AREA) {
            return null;
        }

        return css`
            & g[data-id="${CSS.escape(colorData.planner_id)}"] {
                path {
                    cursor: pointer;
                    transition: fill ${theme.transition.timingFunction} ${theme.transition.duration};
                    fill: ${areaColor};
                }
            }
        `;
    });
}
