import React, {useRef} from "react";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {elevation} from "@pg-design/elevation";
import {BrandWeightIcon, CheckIcon, ListAddIcon} from "@pg-design/icons-module";

import {useFavourites} from "../../favourite/hooks/use_favourites";
import {hitGtmAddToCompareClick} from "../../favourite/tracking/favourite_gtm_events";
import {gtmFavouriteAddToFavouritesClick} from "../../favourite/tracking/gtm_event_favourites_multilead";
import {handleFavouritesListCheckHit} from "../../tracking/algolytics_hits/favourites_list_hit";
import {ITrackedOffer} from "../../tracking/algolytics_hits/tracked_utils/tracked_offer";
import {ITrackedProperty} from "../../tracking/algolytics_hits/tracked_utils/tracked_property";
import {ITrackedVendor} from "../../tracking/algolytics_hits/tracked_utils/tracked_vendor";

interface IProps {
    disableUntilMount?: boolean;
    offerId: number;
    offerImage?: string;
    propertyId?: number;
    trackedOffer: ITrackedOffer;
    trackedVendor: ITrackedVendor;
    trackedProperty?: ITrackedProperty;
    size?: string;
    withText?: boolean;
    disableInfoText?: boolean;
    className?: string;
}

export const FavouriteIcon = (props: IProps) => {
    const {size = "2.6", propertyId, offerId} = props;
    const theme = useTheme();
    const iconRef = useRef<HTMLDivElement | null>(null);
    const {
        isOfferInFavourites,
        isPropertyInFavourites,
        addPropertyToFavourites,
        addOfferToFavourites,
        removePropertyFromFavourites,
        removeOfferFromFavourites
    } = useFavourites();

    const isInFavourites = propertyId ? isPropertyInFavourites(propertyId) : isOfferInFavourites(offerId);

    const onFavouriteToggle = async () => {
        const {offerId, propertyId} = props;

        if (propertyId && isInFavourites) {
            await removePropertyFromFavourites(propertyId);

            hitGtmAddToCompareClick(false);
            handleFavouritesListCheckHit(false, offerId, propertyId);
        } else if (!propertyId && isInFavourites) {
            await removeOfferFromFavourites(offerId);

            gtmFavouriteAddToFavouritesClick(false);
            handleFavouritesListCheckHit(false, offerId);
        } else if (propertyId && !isInFavourites) {
            await addPropertyToFavourites(propertyId, offerId);

            hitGtmAddToCompareClick(true);
            handleFavouritesListCheckHit(true, offerId, propertyId);
        } else {
            //  !propertyId && !isInFavourites
            await addOfferToFavourites(offerId);

            gtmFavouriteAddToFavouritesClick(true);
            handleFavouritesListCheckHit(true, offerId);
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        if (!props.withText) {
            e.stopPropagation();
        }

        e.preventDefault();
        onFavouriteToggle();
    };

    const renderIcon = (): React.ReactNode => {
        if (isInFavourites) {
            return <CheckIcon size={size} wrapperSize="4" wrapperColor={theme.colors.primary} css={elevation(1)} />;
        } else if (!isInFavourites && propertyId) {
            return <BrandWeightIcon size={size} wrapperSize="4" wrapperColor="#fff" css={elevation(1)} />;
        } else {
            return <ListAddIcon size={size} wrapperSize="4" wrapperColor="#fff" css={elevation(1)} />;
        }
    };

    const renderWithText = (): React.ReactNode => {
        return (
            // TODO: the appearance of this button should be consulted with UX/UI specialist
            // It is currently not used in app
            <Button variant="none_secondary" iconLeft={ListAddIcon}>
                <span>Dodaj do listy ulubionych</span>
            </Button>
        );
    };

    return (
        <div css={favouriteIconBaseStyle} data-id={`favouriteIcon-${props.offerId}`} onClick={handleClick} ref={iconRef} className={props.className}>
            {props.withText ? renderWithText() : renderIcon()}
        </div>
    );
};

//  Styles
const favouriteIconBaseStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
